import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkAuth } from '../../actions/auth'

const Protector = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {isAuthenticated,authData} = useSelector((state)=>state.auth)
    useEffect(()=>{
        !isAuthenticated && navigate('/login')
        if(isAuthenticated)
          dispatch(checkAuth(authData ))
    },[isAuthenticated])
  return (
    <>
    </>
  )
}

export default Protector
