import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login, verify } from '../../actions/auth'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [verified, setVerified] = useState(true)
    const [code, setCode] = useState()


    const initialState = {
        email: "",
        password: "",
    }


    const [authData, setAuthData] = useState(initialState)

    const handleChange = (e) => {
        setAuthData({ ...authData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(authData)
        dispatch(login(authData, navigate, setVerified))
    }
    const handleChange2 = (e) => {
        setCode(e.target.value)
    }

    const handleVerify = (e) => {
        e.preventDefault()
        // console.log(code)
        // console.log(authData)
        dispatch(verify(code, navigate))
    }
    return (
        <>
            <br />
            <section>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        {
                            verified ? (<>
                                <Typography component="h1" variant="h5">
                                    Log in
                                </Typography>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        onChange={handleChange}
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        name="password"
                                        onChange={handleChange}
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Log In
                                    </Button>
                                </Box>
                            </>
                            ) : (
                                <>
                                    <Typography component="h1" variant="h5">
                                        User Verification
                                    </Typography>
                                    <Typography variant="caption">
                                        A verification code has been sent to {authData.email}
                                    </Typography>
                                    <Box component="form" onSubmit={handleVerify} noValidate sx={{ mt: 1 }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="verify"
                                            label="Verification Code"
                                            name="Verification Code"
                                            onChange={handleChange2}
                                            autoComplete="Verification Code"
                                            autoFocus
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Verify
                                        </Button>
                                    </Box>
                                </>
                            )}
                    </Box>
                </Container>
            </section>
            <br />
        </>
    )
}

export default Login
