import axios from 'axios';

const API = axios.create({baseURL: 'https://server.rentomart.in'})

API.interceptors.request.use((req)=>{
    if(localStorage.getItem('profile'))
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
        return req;
})
// this function will run like a middleware for other requests

export const getProducts = () => API.get(`/admin/products`);
export const getCategories = () => API.get(`/admin/categories`);
export const getSubCategories = () => API.get(`/admin/sub-categories`);
export const postProduct = (data) => API.post(`/admin/post/products`,data);
export const postCategory = (data) => API.post(`/admin/post/category`,data);
export const postSubCategory = (data) => API.post(`/admin/post/sub-category`,data);
export const getUsers = () => API.get(`/admin/users`);
export const getAdminUsers = () => API.get(`/admin/admin-users`);
export const getUserCount = () => API.get(`/admin/user-count`);
export const logIn = (formData) => API.post('/admin/login',formData)
export const verify = (code, authData) => API.get(`/admin/${authData.result._id}/verify/${code}`)
export const adminSignup = (data) =>API.post('/admin/signup',data)
export const deleteProduct = (id) => API.delete(`/admin/product/${id}`);
export const deleteCategory = (id) => API.delete(`/admin/category/${id}`);
export const deleteSubCategory = (id) => API.delete(`/admin/sub-category/${id}`);
