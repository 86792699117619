export const userInputs = [
    {
      id: 1,
      label: "Username",
      type: "text",
      placeholder: "username",
    },
    {
      id: 2,
      label: "FullName",
      type: "text",
      placeholder: "Full Name",
    },
    {
      id: 3,
      label: "Email",
      type: "mail",
      placeholder: "email",
    },
    {
      id: 4,
      label: "Phone",
      type: "text",
      placeholder: "Mobile Number",
    },
    {
      id: 5,
      label: "Password",
      type: "password",
    },
    // {
    //   id: 6,
    //   label: "Address",
    //   type: "text",
    //   placeholder: "Elton St. 216 NewYork",
    // },
    // {
    //   id: 7,
    //   label: "Country",
    //   type: "text",
    //   placeholder: "USA",
    // },
  ];
export const adminUserInputs = [
    {
      id: 1,
      label: "Admin Id",
      name: "adminId",
      type: "text",
      placeholder: "Admin Id",
    },
    {
      id: 2,
      label: "Emp Id",
      name: "empId",
      type: "text",
      placeholder: "Emp Id",
    },
    {
      id: 3,
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "email",
    },
    {
      id: 5,
      label: "Password",
      name: "password",
      type: "text",
    },
    {
      id: 6,
      label: "Mobile Number",
      name: "mobileNumber",
      type: "text",
      placeholder:'Mobile Number'
    },
    {
      id: 7,
      label: "Address",
      name: "address",
      type: "text",
      placeholder:'Address'
    },
  ];
  
  export const productInputs = [
    {
      id: 1,
      name: "productName",
      label: "Product Name",
      type: "text",
      placeholder: "Product Name",
    },
    {
      id: 2,
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 4,
      name: "costPerWeek",
      label: "Cost Per Week",
      type: "text",
      placeholder: "Cost Per Week",
    },
    {
      id: 5,
      name: "costPerMonth",
      label: "Cost Per Month",
      type: "text",
      placeholder: "Cost Per Month",
    },
    {
      id: 6,
      name: "noOfStock",
      label: "Stock",
      type: "number",
      placeholder: "Stock",
    },
    {
      id: 7,
      name: "brand",
      label: "Brand",
      type: "text",
      placeholder: "Brand",
    },
    {
      id: 8,
      name: "address",
      label: "Address",
      type: "text",
      placeholder: "Address",
    },
    // {
    //   id: 10,
    //   name: "status",
    //   label: "Status",
    //   type: "text",
    //   placeholder: "Status",
    // },
  ];


  export const categoryInputs = [
    {
      id: 1,
      name: "categoryName",
      label: "Category Name",
      type: "text",
      placeholder: "Category Name",
    },
    {
      id: 2,
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
  ];

  export const subCategoryInputs = [
    {
      id: 1,
      name: "subCategoryName",
      label: "Sub Category Name",
      type: "text",
      placeholder: "Sub Category Name",
    },
    {
      id: 2,
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
  ];
  