import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import FileBase64 from 'react-file-base64'
import { useDispatch, useSelector } from "react-redux";
import { postCategory, postSubCategory } from "../../actions/postData";
import { NativeSelect } from "@mui/material";
import { getCategories } from "../../actions/fetchData";
import { CircularProgress } from "@mui/material";



const NewSubCategory = ({ inputs, title }) => {
    const initialState = {
        subCategoryName: "",
        subCategoryImage: "",
        categoryName: "Other",
        status: "Active",
        description: "",
    }
    const dispatch = useDispatch()
    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        console.log(e)
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        // e.preventDefault()
        try {

            if (
                    !data.subCategoryName ||
                    !data.categoryName ||
                    !data.status ||
                    !data.subCategoryImage ||
                    !data.description
                )
                {alert("All fields must be filled")
            console.log(data)}
            else {
                dispatch(postSubCategory(data))
                setData(initialState)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        !categories.length && dispatch(getCategories())
      },[])
  const {categories, isLoading} = useSelector((state)=>state.category)


    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>Add a Sub Category</h1>
                </div>
                {isLoading ? <CircularProgress/> :
                <div className="bottom">
                    <div className="left">
                        <img
                            src=
                            {
                                data.subCategoryImage || "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                            }
                            alt="Hello"
                        />
                    </div>
                    <div className="right_S">
                        <form onSubmit={handleSubmit}>
                            <div className="formInput">
                                <label htmlFor="file">
                                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                                </label>
                                <FileBase64
                                    type='file'
                                    multiple={false}
                                    onDone={({ base64 }) => setData({ ...data, subCategoryImage: base64 })}
                                />
                            </div>

                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input name={input.name} value={data[input.name]} type={input.type} placeholder={input.placeholder} onChange={handleChange} />
                                </div>
                            ))}
                            <div  className="formInput">
                  <label >
                  Category
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={"Other"}
                  inputProps={{
                    name: 'categoryName',
                    id: 'categoryName',
                  }}
                >
                  {categories.map((c)=>(
                      <option key={c._id} value={c.categoryName}>{c.categoryName}</option>
                  ))}
                </NativeSelect>
              </div>
                            <div className="formInput">
                                <label>
                                    Status
                                </label>
                                <NativeSelect className="formInput" onChange={handleChange}
                                    defaultValue={'Active'}
                                    inputProps={{
                                        name: 'status',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'Active'}>Active</option>
                                    <option value={'Inactive'}>Inactive</option>
                                </NativeSelect>
                            </div>
                            <button type='submit' >Send</button>
                        </form>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default NewSubCategory;
