import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import NewCategory from "./pages/new/NewCategory";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs, userInputs, categoryInputs, subCategoryInputs, adminUserInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import NewSubCategory from "./pages/new/NewSubCategory";
import NewAdmin from "./pages/new/NewAdmin";
import Protector from "./pages/protector/Protector";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Protector/>
        <Routes>
          <Route path="/">

            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="users">
              <Route index element={<List type={"Users"}/>} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                // element={<New inputs={userInputs} title="Add New User" />}
                element={<List type={"Users"}/>}
              />
            </Route>
            <Route path="admin-users">
              <Route index element={<List type={"Admin Users"}/>} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<NewAdmin inputs={adminUserInputs} title="Add New User" />}
              />
            </Route>
            <Route path="products">
              <Route index element={<List type={"Products"} />} />
              <Route path=":productId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="categories">
            <Route index element={<List type={"Categories"} />} />
              <Route 
                path="new"
                element={<NewCategory inputs={categoryInputs} tite="Add New Category" />}
              />
            </Route>
            <Route path="sub-categories">
            <Route index element={<List type={"Sub Categories"} />} />
              <Route 
                path="new"
                element={<NewSubCategory inputs={subCategoryInputs} tite="Add New Sub Category" />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
