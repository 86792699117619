import { AUTH, LOGOUT } from "../constants/actionTypes";

const authReducer = (state = {authData: null, isAuthenticated:false}, action)=>{
    switch (action.type) {
        case AUTH:
            // console.log(action.payload)
            localStorage.setItem('profile', JSON.stringify({...action.payload }))
            return {...state, authData: {...action.payload, date:new Date()}, isAuthenticated:true};
        case LOGOUT:
            return {...state, authData: null, isAuthenticated:false};  
        default:
            return state;
    }
}

export default authReducer