import * as api from '../api';
import { DELETE_CATEGORY, DELETE_PRODUCT, DELETE_SUB_CATEGORY } from '../constants/actionTypes';

export const deleteProduct = (id) => async(dispatch) =>{
    try{
        await api.deleteProduct(id);
        dispatch({type:DELETE_PRODUCT,payload: id});
    }
    catch(err)
    {
        console.log(err.message);
    }
}
export const deleteCategory = (id) => async(dispatch) =>{
    try{
        await api.deleteCategory(id);
        dispatch({type:DELETE_CATEGORY,payload: id});
    }
    catch(err)
    {
        console.log(err.message);
    }
}
export const deleteSubCategory = (id) => async(dispatch) =>{
    try{
        await api.deleteSubCategory(id);
        dispatch({type:DELETE_SUB_CATEGORY,payload: id});
    }
    catch(err)
    {
        console.log(err.message);
    }
}