import { combineReducers } from "redux";
import products from './products.js'
import category from './category.js'
import users from './users.js'
import auth from './auth.js'
import {
    FLUSH, PAUSE,
    PERSIST, persistReducer, PURGE,
    REGISTER, REHYDRATE
  } from 'redux-persist';
  import storage from 'redux-persist/lib/storage';



export default combineReducers({
    products:products,
    users:users,
    category:category,
        auth:persistReducer(
        {
          key: 'auth',
          storage
        },
        auth
      ),
})