import * as api from '../api';
import { AUTH, LOGOUT } from '../constants/actionTypes';


export const verify = (code, navigate) => async(dispatch) => {
    try {
        const authData = JSON.parse(localStorage.getItem('profile'))
        console.log(authData)
        console.log("Hellllloooooo")
        console.log(authData.result._id)
        const {data} = await api.verify(code, authData);
        // alert(data.message)
        console.log(data)
        dispatch({type:AUTH, payload:data})
        // dispatch({type:SIGNUP})
        navigate('/') 
    } catch (error) {
        console.log(error)
    }
}

export const login = (authData, navigate, setVerified) => async(dispatch) => {
    try {
        const {data} = await api.logIn(authData);

        // console.log(data)
        

        
        dispatch({type:AUTH,payload:data})

        navigate('/')
    } catch (error) {
        alert(error.response.data.message)
        console.log(error)
        if(error.response.data.message === "An Email sent to your account please verify")
        {
            setVerified(false)
            const data = error.response.data
            console.log(data)
            dispatch({type:AUTH,payload:data})
        }    
    }
}

export const checkAuth = (authData) => async(dispatch) =>{
    try {
        if(new Date() - new Date(authData.date) > 3600000  )
            dispatch({type:LOGOUT})
    } catch (error) {
        console.log(error)
    }
}