import * as api from '../api';
import { ADMIN_SIGNUP } from '../constants/actionTypes';

export const adminSignup = (authData) => async(dispatch) => {
    try{
        const {data} = await api.adminSignup(authData);
        // console.log(data)
        // dispatch({type:ADMIN_SIGNUP,payload:data});
    }
    catch(err)
    {
        console.log(err);
        alert(err.response.data.message)
    }
}