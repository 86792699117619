import * as api from '../api';
import {  END_LOADING, FETCH_ADMIN_USERS, FETCH_CATEGORIES, FETCH_PRODUCTS, FETCH_SUB_CATEGORIES, FETCH_USERS, FETCH_USER_COUNT, START_LOADING } from '../constants/actionTypes';



export const getProducts = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getProducts();
        dispatch({type:FETCH_PRODUCTS,payload:data});
        // console.log("h")
        dispatch({type:END_LOADING})
    }
    catch(err)
    {
        console.log(err);
    }
}

export const getCategories = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getCategories();
        dispatch({type:FETCH_CATEGORIES,payload:data});
        dispatch({type:END_LOADING})
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}

export const getSubCategories = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getSubCategories();
        dispatch({type:FETCH_SUB_CATEGORIES,payload:data});
        dispatch({type:END_LOADING})
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}

export const getUsers = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getUsers();
        dispatch({type:FETCH_USERS,payload:data});
        dispatch({type:END_LOADING})
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}


export const getUserCount = () => async(dispatch) => {
    try{
        const {data} = await api.getUserCount();
        // console.log(data.count)
        dispatch({type:FETCH_USER_COUNT,payload:data.count});
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}

export const getAdminUsers = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getAdminUsers();
        dispatch({type:FETCH_ADMIN_USERS,payload:data});
        dispatch({type:END_LOADING})
    }
    catch(err)
    {
        console.log(err);
    }
}
