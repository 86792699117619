import { END_LOADING, FETCH_ADMIN_USERS, FETCH_USERS, FETCH_USER_COUNT, START_LOADING } from "../constants/actionTypes";


const userReducer = (state = {users: [], count:0, adminUsers: [], adminCount:0, isLoading: false}, action)=>{
    switch (action.type) {
        case FETCH_USERS:
            return {...state, users: action.payload.data, count:action.payload.count};
        case FETCH_ADMIN_USERS:
            return {...state, adminUsers: action.payload.data, adminCount:action.payload.count};
        case FETCH_USER_COUNT:
            return {...state, count:action.payload};
        case START_LOADING:
            return {...state, isLoading : true}
        case END_LOADING:
            return {...state, isLoading : false}
        default:
            return state;
    }
}

export default userReducer