import { DELETE_PRODUCT, END_LOADING, FETCH_PRODUCTS, POST_PRODUCT, START_LOADING } from "../constants/actionTypes";


const productReducer = (state = { products: [], count: 0, isLoading: false }, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return { ...state, products: action.payload.data, count: action.payload.count };
        case POST_PRODUCT:
            return { ...state, products: [...state.products, action.payload] }
        case DELETE_PRODUCT:
            return { ...state, products: state.products.filter((product) => product._id !== action.payload) }
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        default:
            return state;
    }
}

export default productReducer