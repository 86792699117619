import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import {  useState } from "react";
import {  NativeSelect } from "@mui/material";
import { useDispatch } from "react-redux";
import { adminSignup } from "../../actions/admin";


const New = ({ inputs, title }) => {
  const initialState = {
        adminId:"",
        status:"active",
        empId:"",
        address:"",
        email:"",
        role:"other",
        password:"",
        mobileNumber:"",
  }
  const dispatch = useDispatch()
  const [data, setData] = useState(initialState);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
}


  const handleSubmit = (e) =>{
    e.preventDefault()

    try {
      setData({...data})
  
      if (Object.values(data).includes(""))
        {
            alert(`All fields must be filled`)
            setData(data) 
            console.log(data)       
        }
      else{
        dispatch(adminSignup(data))
        setData(initialState)
      }
    } catch (error) {
      console.log(error)  
    }
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input name={input.name} value={data[input.name]} type={input.type} placeholder={input.placeholder} onChange={handleChange}/>
                </div>
              ))}

              <div className="formInput">
                  <label>
                  Role 
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={'other'}
                  inputProps={{
                    name: 'role',
                    id: 'role',
                  }}
                >
                  <option value={'other'}>Other</option>
                  <option value={'admin'}>Admin</option>
                </NativeSelect>
              </div>
              <div className="formInput">
                  <label>
                  Status 
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={'active'}
                  inputProps={{
                    name: 'status',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'active'}>Active</option>
                  <option value={'inActive'}>Inactive</option>
                </NativeSelect>
              </div>
              <button type='submit' >Send</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
