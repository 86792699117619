import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/Datatable"
import DatatableC from "../../components/datatable/DatatableC"
import DatatableS from "../../components/datatable/DatatableS"
import DatatableU from "../../components/datatable/DatatableU"
import DatatableA from "../../components/datatable/DatatableA"

const List = ({type}) => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        {type==='Categories'?<DatatableC type = {type}/> :type==='Sub Categories'?<DatatableS type={type}/> : type==='Users' ? <DatatableU type={type}/> : type === "Admin Users"? <DatatableA type={type} /> :  <Datatable type={type}/>}
      </div>
    </div>
  )
}

export default List