import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { CircularProgress, InputLabel, NativeSelect } from "@mui/material";
import FileBase64 from 'react-file-base64'
import { useDispatch, useSelector } from "react-redux";
import { postProduct } from "../../actions/postData";
import { getCategories, getSubCategories } from "../../actions/fetchData";


const New = ({ inputs, title }) => {
  const initialState = title === "Add New Product" ? {
    productName:"",
    availability:"In Stock",
    category:"Other",
    subCategory:"Other",
    address:"",
    noOfStock:"",
    costPerWeek:"",
    costPerMonth:"",
    productImages:"",
    description:"",
    status:"Active",
    brand:"",
    featured:"yes",
  } : {
    fullName:"",
    username:"",
    email:"",
    password:"",
    mobileNumber:"",
    verified:"",
    mobileVerified:"",
  }
  const dispatch = useDispatch()
  const [productImages, setProductImages] = useState([]);
  const [images, setImages] = useState([]);
  const [data, setData] = useState(initialState);
  const {subCategories, categories, isLoading} = useSelector((state)=>state.category)



  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
}
  useEffect(()=>{
    console.log(categories)
    !categories.length && dispatch(getCategories())
    !subCategories.length && dispatch(getSubCategories())
  },[])

  
  
  // data.subCategory = subCategories?.find((c)=>(c.categoryName === data.category))
  // data.subCategory = data.subCategory?.subCategoryName
  // console.log(categories)
  // const handleImageChange = (e) => {
  //   // const files = Array.from(e.target.files);
  //   const files = Array.from(e);

  //   files.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         // console.log(productImages)
  //         setProductImages([reader.result, ...productImages]);
  //         // console.log(reader.result)
  //         // console.log(productImages)
  //       }
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // }

  const handleSubmit = (e) =>{
    // e.preventDefault()

    
    // console.log(productImages)
    try {
      // console.log(productImages)
      setData({...data,productImages})
      // console.log(data)
  
      if (
        !data.productName || 
        !data.availability || 
        !data.category || 
        !data.subCategory || 
        !data.address || 
        !data.noOfStock || 
        !data.costPerWeek || 
        !data.costPerMonth || 
        !data.productImages || 
        !data.description || 
        !data.status || 
        !data.brand || 
        !data.featured
        )
      // {
        // if(
          // !data.productImages)
        // {
          // setData({...data,productImages})
          // console.log(productImages)

          // handleSubmit(e)
        // }
        // else
        alert("All fields must be filled")
        // console.log(data)}
      // }
      else{
        dispatch(postProduct(data))
        setData(initialState)
        setProductImages([])
        setImages([])
      }
    } catch (error) {
      console.log(error)  
    }
  }

  return (
    isLoading ? <CircularProgress/> :
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src= 
              {
                data.productImages[0] || "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt="Hello"
            />
          </div>
          <div className="right_S">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <FileBase64
                type='file'
                multiple={false}
                onDone={({base64})=>setData({...data,productImages:[base64, ...data.productImages ]} )}
                /> 

                {/* <input
                  type="file"
                  multiple
                  name='productImages'
                  id="file"
                  accept='image/*'
                  onChange={(e)=>setImages([e?.target?.files[0],...images])}
                  style={{ display: "none" }}
                /> */}
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input name={input.name} value={data[input.name]} type={input.type} placeholder={input.placeholder} onChange={handleChange}/>
                </div>
              ))}
              {title === "Add New Product" &&
              <>
              <div className="formInput">
                  <label>
                  Category 
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={"Other"}
                  inputProps={{
                    name: 'category',
                    id: 'uncontrolled-native',
                  }}
                >
                  {categories.map((c)=>(
                      <option key={c?._id} value={c?.categoryName}>{c?.categoryName}</option>
                  ))}
                </NativeSelect>
              </div>
              <div className="formInput">
                  <label>
                  Sub Category 
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={"Other"}
                  inputProps={{
                    name: 'subCategory',
                    id: 'uncontrolled-native',
                  }}
                >
                  {subCategories.map((c)=>(c.categoryName === data.category) && (
                      <option key={c?._id} value={c?.subCategoryName}>{c?.subCategoryName}</option>
                  ))}
                </NativeSelect>
              </div>
              <div className="formInput">
                  <label>
                  Status
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={'Active'}
                  inputProps={{
                    name: 'status',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'Active'}>Active</option>
                  <option value={'Inactive'}>Inactive</option>
                </NativeSelect>
              </div>
              <div className="formInput">
                  <label>
                  Featured
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={'Yes'}
                  inputProps={{
                    name: 'featured',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'Yes'}>Yes</option>
                  <option value={'No'}>No</option>
                </NativeSelect>
              </div>
              <div className="formInput">
                  <label>
                    Availability of the Product
                  </label>
                <NativeSelect className="formInput" onChange={handleChange}
                  defaultValue={'In Stock'}
                  inputProps={{
                    name: 'availability',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'In Stock'}>In Stock</option>
                  <option value={'Out of Stock'}>Out of Stock</option>
                </NativeSelect>
              </div>
              </>}
              <button type='submit' >Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
