import { DELETE_CATEGORY, DELETE_SUB_CATEGORY, END_LOADING, FETCH_CATEGORIES, FETCH_SUB_CATEGORIES, POST_CATEGORY, POST_SUB_CATEGORY, START_LOADING } from "../constants/actionTypes";


const categoryReducer = (state = {categories: [],subCategories: [], countC:0, countS:0, isLoading: false}, action)=>{
    switch (action.type) {
        case FETCH_CATEGORIES:
            return {...state, categories: action.payload.data, countC:action.payload.count};
        case FETCH_SUB_CATEGORIES:
            return {...state, subCategories: action.payload.data, countS:action.payload.count};
        case POST_CATEGORY:
            return {...state, categories: [...state.categories, action.payload]}
        case DELETE_CATEGORY:
            return { ...state, categories: state.categories.filter((category) => category._id !== action.payload) }
        case DELETE_SUB_CATEGORY:
            return { ...state, subCategories: state.subCategories.filter((subCategory) => subCategory._id !== action.payload) }
        case POST_SUB_CATEGORY:
            return {...state, subCategories: [...state.subCategories, action.payload]}
        case START_LOADING:
            return {...state, isLoading : true}
        case END_LOADING:
            return {...state, isLoading : false}
        default:
            return state;
    }
}

export default categoryReducer