import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import {Provider} from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import reducers from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'







const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

let persistor = persistStore(store);


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
                <App />
        </PersistGate>
    </Provider>,
      document.getElementById("root")
      );

