import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import FileBase64 from 'react-file-base64'
import { useDispatch, useSelector } from "react-redux";
import { postCategory } from "../../actions/postData";
import { NativeSelect } from "@mui/material";


const New = ({ inputs, title }) => {
    const initialState = {
        categoryName: "",
        categoryImage: "",
        status: "Active",
        description: "",
    }
    const dispatch = useDispatch()
    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        // e.preventDefault()
        try {

            if (!data.categoryName || !data.categoryImage || !data.status || !data.description)
                alert("All fields must be filled")
            else {
                dispatch(postCategory(data))
                setData(initialState)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <img
                            src=
                            {
                                data.categoryImage || "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                            }
                            alt="Hello"
                        />
                    </div>
                    <div className="right">
                        <form onSubmit={handleSubmit}>
                            <div className="formInput">
                                <label htmlFor="file">
                                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                                </label>
                                <FileBase64
                                    type='file'
                                    multiple={false}
                                    onDone={({ base64 }) => setData({ ...data, categoryImage: base64 })}
                                />
                            </div>

                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input name={input.name} value={data[input.name]} type={input.type} placeholder={input.placeholder} onChange={handleChange} />
                                </div>
                            ))}
                            <div className="formInput">
                                <label>
                                    Status
                                </label>
                                <NativeSelect className="formInput" onChange={handleChange}
                                    defaultValue={'Active'}
                                    inputProps={{
                                        name: 'status',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'Active'}>Active</option>
                                    <option value={'Inactive'}>Inactive</option>
                                </NativeSelect>
                            </div>
                            <button type='submit' >Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default New;
