export const userColumns = [
  {
    field: "user",
    headerName: "User",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://www.iconpacks.net/icons/2/free-icon-user-4253.png"} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "fullName",
    headerName: "Full Name",
    width: 170,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 120
  },
  {
    field: "verified",
    headerName: "Verified",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`verified ${params.row.verified?.toString()}`}>
          {params.row.verified?.toString()}
        </div>
      );
    },
  },
];
export const adminUserColumns = [
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "status",
    headerName: "Status",
    width: 70,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status?.toLowerCase()}`}>
          {params.row.status}
        </div>
      );
    },
  },

  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 120
  },
  {
    field: "address",
    headerName: "Address",
    width: 100,
  },
  {
    field: "adminId",
    headerName: "Admin Id",
    width: 100,
  },
  {
    field: "empId",
    headerName: "Emp Id",
    width: 100,
  },
];

export const productColumns = [
  // { field: "id", headerName: "ID", width: 70 },
  {
    field: "productName",
    headerName: "Product Name",
    width: 230,
    renderCell: (params) => {
      // console.log(userRows)
      // console.log(params.row)
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row?.productImages[0]?.url || "https://www.iconpacks.net/icons/2/free-icon-user-4253.png"} alt="avatar" />
          {params.row.productName}
        </div>
      );
    },
  },
  {
    field: "availability",
    headerName: "Availability",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`availability ${params.row.availability==="In Stock" ? "yes" : "no" }`}>
        </div>
      );
    },
  },

  {
    field: "noOfStock",
    headerName: "Stock",
    width: 60,
    renderCell: (params) => {
      return (
        <div className={`stock`}>
          {params.row.noOfStock}
        </div>
      );
      }
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status?.toLowerCase()}`}>
          {params.row.status}
        </div>
      );
    },
  },
  {
    field: "created",
    headerName: "Created",
    width: 150,
    renderCell: (params) => {
      return (
        <div >
          {params.row.created?.substring(0,10)}
        </div>
      );
    },
  },
  {
    field: "modified",
    headerName: "Modified",
    width: 150,
    renderCell: (params) => {
      return (
        <div >
          {params.row.modified?.substring(0,10)}
        </div>
      );
    },
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 100,
  }
];


export const categoryColumns = [
  {
    field: "categoryName",
    headerName: "Category Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row?.categoryImage?.url || "https://www.iconpacks.net/icons/2/free-icon-user-4253.png"} alt="avatar" />
          {params.row.categoryName}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status?.toLowerCase()}`}>
          {params.row.status}
        </div>
      );
    },
  },
  {
    field: "created",
    headerName: "Created",
    width: 150,
    renderCell: (params) => {
      return (
        <div >
          {params.row.createdAt?.substring(0,10)}
        </div>
      );
    },
  },
  {
    field: "modified",
    headerName: "Modified",
    width: 150,
    renderCell: (params) => {
      return (
        <div >
          {params.row.updatedAt?.substring(0,10)}
        </div>
      );
    },
  }
];

export const subCategoryColumns = [
  {
    field: "subCategoryName",
    headerName: "Sub Category Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row?.subCategoryImage?.url || "https://www.iconpacks.net/icons/2/free-icon-user-4253.png"} alt="avatar" />
          {params.row.subCategoryName}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status?.toLowerCase()}`}>
          {params.row.status}
        </div>
      );
    },
  },
  {
    field: "categoryName",
    headerName: "Category Name",
    width: 230,
  },
  {
    field: "created",
    headerName: "Created",
    width: 150,
    renderCell: (params) => {
      return (
        <div >
          {params.row.createdAt?.substring(0,10)}
        </div>
      );
    },
  },
  {
    field: "modified",
    headerName: "Modified",
    width: 150,
    renderCell: (params) => {
      return (
        <div >
          {params.row.updatedAt?.substring(0,10)}
        </div>
      );
    },
  }
];